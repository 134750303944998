// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---jamchefs-gatsby-theme-shopify-src-pages-404-js": () => import("./../../../@jamchefs/gatsby-theme-shopify/src/pages/404.js" /* webpackChunkName: "component---jamchefs-gatsby-theme-shopify-src-pages-404-js" */),
  "component---jamchefs-gatsby-theme-shopify-src-templates-blog-list-template-js": () => import("./../../../@jamchefs/gatsby-theme-shopify/src/templates/BlogListTemplate.js" /* webpackChunkName: "component---jamchefs-gatsby-theme-shopify-src-templates-blog-list-template-js" */),
  "component---jamchefs-gatsby-theme-shopify-src-templates-blog-template-js": () => import("./../../../@jamchefs/gatsby-theme-shopify/src/templates/BlogTemplate.js" /* webpackChunkName: "component---jamchefs-gatsby-theme-shopify-src-templates-blog-template-js" */),
  "component---jamchefs-gatsby-theme-shopify-src-templates-category-template-js": () => import("./../../../@jamchefs/gatsby-theme-shopify/src/templates/CategoryTemplate.js" /* webpackChunkName: "component---jamchefs-gatsby-theme-shopify-src-templates-category-template-js" */),
  "component---jamchefs-gatsby-theme-shopify-src-templates-collection-template-js": () => import("./../../../@jamchefs/gatsby-theme-shopify/src/templates/CollectionTemplate.js" /* webpackChunkName: "component---jamchefs-gatsby-theme-shopify-src-templates-collection-template-js" */),
  "component---jamchefs-gatsby-theme-shopify-src-templates-exercise-list-template-js": () => import("./../../../@jamchefs/gatsby-theme-shopify/src/templates/ExerciseListTemplate.js" /* webpackChunkName: "component---jamchefs-gatsby-theme-shopify-src-templates-exercise-list-template-js" */),
  "component---jamchefs-gatsby-theme-shopify-src-templates-exercise-template-js": () => import("./../../../@jamchefs/gatsby-theme-shopify/src/templates/ExerciseTemplate.js" /* webpackChunkName: "component---jamchefs-gatsby-theme-shopify-src-templates-exercise-template-js" */),
  "component---jamchefs-gatsby-theme-shopify-src-templates-home-page-template-js": () => import("./../../../@jamchefs/gatsby-theme-shopify/src/templates/HomePageTemplate.js" /* webpackChunkName: "component---jamchefs-gatsby-theme-shopify-src-templates-home-page-template-js" */),
  "component---jamchefs-gatsby-theme-shopify-src-templates-page-template-js": () => import("./../../../@jamchefs/gatsby-theme-shopify/src/templates/PageTemplate.js" /* webpackChunkName: "component---jamchefs-gatsby-theme-shopify-src-templates-page-template-js" */),
  "component---jamchefs-gatsby-theme-shopify-src-templates-product-page-template-js": () => import("./../../../@jamchefs/gatsby-theme-shopify/src/templates/ProductPageTemplate.js" /* webpackChunkName: "component---jamchefs-gatsby-theme-shopify-src-templates-product-page-template-js" */),
  "component---jamchefs-gatsby-theme-shopify-src-templates-workout-category-template-js": () => import("./../../../@jamchefs/gatsby-theme-shopify/src/templates/WorkoutCategoryTemplate.js" /* webpackChunkName: "component---jamchefs-gatsby-theme-shopify-src-templates-workout-category-template-js" */),
  "component---jamchefs-gatsby-theme-shopify-src-templates-workout-list-template-js": () => import("./../../../@jamchefs/gatsby-theme-shopify/src/templates/WorkoutListTemplate.js" /* webpackChunkName: "component---jamchefs-gatsby-theme-shopify-src-templates-workout-list-template-js" */),
  "component---jamchefs-gatsby-theme-shopify-src-templates-workout-template-js": () => import("./../../../@jamchefs/gatsby-theme-shopify/src/templates/WorkoutTemplate.js" /* webpackChunkName: "component---jamchefs-gatsby-theme-shopify-src-templates-workout-template-js" */)
}

